
/* Carosel.css */

/* Styles for the previous button */
.slick-prev {
    background-color: #b2b1fd !important; 
    border-radius: 50%; /* Optional: make the button circular */
    width: 40px;
    height: 40px;
    z-index: 1; /* Ensure the button appears above the content */
  }
  
  .slick-prev:before {
    color: #ffffff !important; /* Set the arrow color to white */
    font-size: 20px; /* Set the size of the arrow */
  }
  
  /* Styles for the next button */

  .slick-next {
    background-color: #b2b1fd !important; 
    /* Set the background color to black */
    border-radius: 50%; /* Optional: make the button circular */
    width: 40px;
    height: 40px;
    z-index: 1; /* Ensure the button appears above the content */
    /* box-shadow: 0 0 10px rgb(156, 29, 29); */
  }



  /* Optional: Add hover effects for better UX */