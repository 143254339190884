.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Style pour cacher la barre de défilement horizontale */
.scrollbar-hidden {
  scrollbar-width: none;
  /* Pour les navigateurs basés sur Firefox */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
  /* Pour les navigateurs basés sur WebKit comme Chrome et Safari */
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* styles.css */
.highlight {
  position: absolute;
  left: 0;
  right: 0;
  height: 3px;
  background-color: white;
  bottom: -20px;
  transform-origin: left;
  /* Début de l'animation à partir de la gauche */
  transform: scaleX(0);
  /* Initialement invisible */
  transition: transform 0.3s ease;
  /* Animation fluide */
}

.highlight.active {
  transform: scaleX(1);
  /* Pleine largeur lorsque actif */
}

/* styles.css */
.sticky {
  position: -webkit-sticky;
  /* Pour les navigateurs Webkit */
  position: sticky;
  top: 10px;
  /* Ajustez cette valeur pour définir la distance du haut de la page */
  z-index: 100;
  /* Assurez-vous que le composant reste au-dessus des autres éléments */
}

.header-container {
  position: relative;
  z-index: 0;
  overflow: hidden;
  height: 69vh;
  /* ou la hauteur désirée */
  background-size: cover;
  background-position: top;
  /* Afficher la partie basse de l'image */
}

.header-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.header-content {
  position: relative;
  z-index: 10;
  color: white;
  display: flex;
  align-items: center;
  height: 100%;
}

.header-content img {
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.header-content img:hover {
  transform: scale(1.1);
}



/* Animation Navbar */
/* styles.css */

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 100;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 100;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.slide-in {
  animation: slideIn 0.2s ease-in-out;
}

.slide-out {
  animation: slideOut 0.2s ease-in-out;
}