.carousel-container {
    width: 100%;
    margin: auto;
    /* padding: 40px 0; */
  }
  
  .carousel-item {
    padding: 10px;
  }

  .carousel-item-mobile {
    padding-right: 3px;
  }

  .carousel-item img {
    width: 100%;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out; 
    display: flex;
    gap: 1;
  }


  .carousel-item-mobile img{
    width: 100%;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out; 
    /* display: flex; */
    /* gap: 2; */
  }

  .carousel-item-2 img {
    width: 100%;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out; 
  }

  .carousel-item img:hover {
    transform: scale(1.10);
  }

  .carousel-item-2 img:hover {
    transform: scale(1.02);
  }

  .carousel-item-mobile  img:hover{
    transform: scale(1.02);
  }
  