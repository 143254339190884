@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Paytone+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poetsen+One&family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@tailwind components;
@tailwind utilities;

/* @import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities'; */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body::-webkit-scrollbar {
  width: 5px;   
}

body::-webkit-scrollbar-track {
         background: orange; 
         width: 5px;               

}

body::-webkit-scrollbar-thumb {
  background-color: #292929;    
  border-radius:10px;       
} */

::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dasableImage {
  user-select: none; /* Standard */
}

.imagetest {
  background: linear-gradient(to bottom, rgb(68, 69, 71), rgba(36, 36, 97, 0.3)),
    url('/src/assets/uty-market.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.gradiant {
  background: linear-gradient(
    to bottom,
    rgb(68, 69, 71),
    rgba(36, 36, 97, 0.3)
  );
}

.filter-data {
  filter: blur(30px);
}

.filter-comment {
  filter: blur(50px);
}

.filter-data-2 {
  filter: blur(1px);
}

::-webkit-scrollbar {
  width: 1;
  /* background-color: transparent; */
}

/* Loader image */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.shadow-bth {
  box-shadow: 2px 2px 6px rgba(186, 186, 186, 0.67);
}

.shadow-profil {
  box-shadow: 0px 0px 6px rgba(51, 49, 49, 0.67);
}

.shadow-sidebar:hover {
  box-shadow: 0 0 4px #23b453;
}

.focusInput {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid white;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

.focusInput:focus {
  border: 1px solid orange;
  border-radius: 4px;
}

.focusInputSearch {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid white;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

.focusInputSearch:focus {
  border: 1px solid orange;
  border-radius: 12px;
}

.round {
  border-top-left-radius: 18px;
}

.shadow-delete {
  box-shadow: 0 0 15px rgba(48, 48, 48, 0.19);
}

.shadow-nav {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}

.shadow-message {
  box-shadow: 0 0 1px rgba(37, 37, 37, 0.19);
}

/* Animation */

/* // Set the Stage */
/* body {
  background: #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.wrapper {
  margin: 30px;
  padding: 30px;
  background: #fff;
  width: 360px;
  height: 640px;
  display: flex;
  flex-direction: column;
}

.wrapper-cell {
  display: flex;
  margin-bottom: 30px;
}

/* // Animation */
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.animated-background-msg {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #c4bebe;
  background: linear-gradient(to right, #c4bebe 8%, #ccc5c5 18%, #dbd5d5 33%);
  background-size: 800px 104px;
  /* height: 56px; */
  position: relative;
}

/* // Page Elements */
.image {
  /* //  background: #F6F6F6; */
  @extend .animated-background;
}

.text {
  margin-left: 20px;
}

.text-line {
  height: 10px;
  width: 230px;
  /* //  background: #F6F6F6; */
  margin: 4px 0;
  @extend .animated-background;
}

.slick-dots { position: absolute; bottom: 10px; left: 50%; transform: translateX(-50%); display: flex !important; justify-content: center; padding: 0; margin: 0; list-style: none; } .slick-dots li button:before { color: white; /* Change la couleur des points */ } .slick-dots li.slick-active button:before { color: red; /* Change la couleur du point actif */ }